body {
    font-family: 'Roboto', sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    padding: 20px;
    box-sizing: border-box;
}

 .rankings-dt {
     position: absolute;
     top: 0;
     right: 0;
     background-color: #fff;
     padding: 10px;
     border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
 }
.spreadsheet {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.rankings-dt-container {
    padding: 20px;
    position: relative;
}
.queue {
    width: 300px;
    margin-left: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: sticky;
    top: 20px;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
}

h3 {
    margin-top: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

th {
    cursor: pointer;
    position: sticky;
        background-color: #f8f8f8;
        top: 0;
        z-index: 1000;
}

th:hover {
    background-color: #f1f1f1;
}

tbody tr:hover {
    background-color: #f9f9f9;
}

button {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.button-row {
    padding: 10px 15px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
button:hover {
    background-color: #1565c0;
}

button:disabled {
    background-color: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 10px;
}

.filter-input {
    width: 99%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.queue-item {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.queue-item button {
    background-color: #d32f2f;
}

.queue-item button:hover {
    background-color: #c62828;
}

.MuiDialog-paper {
    width: 400px;
}